import create from "zustand";
import { formatDate } from "utils/date";

const defaults = {
    analysis: [],
    sections: [],
    section: -1,
    partIndex: null,
    currentQuestion: null,
    summary: {},
    details: {},
    mockType: null
};

const useStore = create((set, get) => ({
    ...defaults,
    setSection: (section) => set({ section }),
    setAttempt: (attempt) => set(() => {
        return {
            summary: attempt.summary,
            analysis: attempt.sections,
            sections: attempt.mockSections,
            mockType: attempt.type,
            details: {
                name: attempt.user.name, email: attempt.user.email,
                date: formatDate(attempt.createdAt),
                testType: attempt.type,
                attemptId: attempt._id,
            }
        };
    }),
    setCurrentQuestion: (currentQuestion) => set({ currentQuestion }),
    setPartIndex: (partIndex) => set({ partIndex }),
    reset: () => set(defaults),
}),
);

export default useStore;