import React from 'react';
import { useSearchParams } from 'react-router-dom';
import AppShell from 'components/AppShell';
import Main from './Main';
import Testimonials from './Testimonials';
import Faq from './Faq';
import Features from './Features';
import NGO from './NGO';
import SectionsCarousal from './SectionsCarousal';
import PlatformComparisions from './PlatformComparisions';
import Blogs from './Blogs';

function Home() {
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    (() => {
      const referralCode = searchParams.get('rc');

      if (referralCode) localStorage.setItem('rc', referralCode);
    })();
  }, [searchParams]);

  return (
    <AppShell>
      <Main />
      <Features />
      <SectionsCarousal />
      <PlatformComparisions />
      <Testimonials />
      <Blogs />
      <NGO />
      <br /><br />
      <Faq />
      <br />
      <br />
    </AppShell>
  );
}

export default Home;