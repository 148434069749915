import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import useStore from 'utils/ieltsStore';

export const instructions = {
  "LISTENING": {
    to: [
      "Answer all the questions.",
      "You can change your answers at any time during the test.",
    ],
    for: [
      "Each question cames one mark.",
      "You wil hear each part's audio once.",
      "For each part of the test there will be time for you to look through the questions and time for you to check your answers.",
      "You can prepare a piece of paper and a pencil to take notes during this test.",
      "If you change the part in between the audio is being played of one part, the audio won't be played again for that part."
    ],
  },
  "WRITING": {
    to: [
      "Answer all the questions.",
      "You can change your answers at any time during the test.",
    ],
    for: [
      "Each part contributes equally to the total writing score.",
      "The test clock will be shown when there are 10 minutes and 5 minutes remaining.",
    ],
  },
  "READING": {
    to: [
      "Answer all the questions.",
      "You can change your answers at any time during the test.",
    ],
    for: [
      "Each question cames one mark.",
      "You wil hear each part once.",
      "For each part of the test there will be time for you to look through the questions and time for you to check your answers.",
      "You can prepare a piece of paper and a pencil to take notes during this test.",
    ]
  },
  "SPEAKING": {
    to: [
      "Answer all the questions",
      "You can change your answers at any time during the test.",
      <>
        <b>Before you start:</b>
        <ul>
          <li>For accurate grading, make sure you are in an environment that is similar to an IELTS testing centre.</li>
          <li>Your score might be affected if the audio includes any sound other than the voice of the speaker.</li>
        </ul>
      </>,
      <>
        <b>To see if you are ready to take the test, check out the following:</b>
        <ul>
          <li>You have allowed access to the microphone on my browser.</li>
          <li>You have a fully functional microphone with no issues in audio quality.</li>
          <li>You made sure no external factors could potentially affect the quality of my recording(dog barking, traffic noises, people talking, etc.)</li>
        </ul>
      </>
    ],
    for: [
      "You will be asked questions by AI. Listen properly to the audio and speak after the beep sound played.",
    ]
  }
};

export function ListItem(props) {
  return (
    <Typography
      variant="body2" mt={1} color="#535353" component="div"
      display="inline-flex" {...props}
    >
      <span
        style={{
          marginTop: 6, marginRight: 16, backgroundColor: "#535353",
          padding: 4, height: 8, borderRadius: "50%"
        }}
      />
      <span style={{ display: "inline-block" }}>{props.children}</span>
    </Typography>
  );
}

function SectionInstructions(
  { active, name = "", time = 300, onNext }
) {
  const { parts = [], questions = [] } = useStore(state => state.sections[state.section]);

  return active ? (
    <Box
      display="flex" width="100%" height="fit-content" py={2} px={6}
      justifyContent="center" position="relative" mx="auto" maxWidth={"80%"}
      flexDirection="column" backgroundColor="white.main" borderRadius={2}
      boxShadow="0px 4px 24px rgba(0, 0, 0, 0.16)"
    >
      <Box flex={"1 1 auto"} display="flex" flexDirection="column" >
        <Typography variant="h5" fontWeight="600" textAlign="center">
          IELTS {name} Academic Test
        </Typography>
        <Typography variant="caption" textAlign="center" color="#00000099">
          {time && (
            <>
              In total, you have
              <span style={{ color: "#02569d" }}>
                <b>&nbsp;{Math.round(time / 60)} minutes&nbsp;</b>
              </span>
              to complete this section.
            </>
          )}
        </Typography>
        <Typography variant="subtitle1" fontWeight="500">
          Instructions to Test Takers
        </Typography>
        {instructions[name.toUpperCase()]?.to?.map((instruction, i) => (
          <ListItem key={i}>{instruction}</ListItem>
        ))}
        <ListItem>
          There are {parts.length} part(s) in this section.
        </ListItem>
        <ListItem>
          There are {questions.length} questions in this section.
        </ListItem>
        {instructions[name.toUpperCase()]?.for?.map((instruction, i) => (
          <ListItem key={i}>{instruction}</ListItem>
        ))}
      </Box>
      <br /><br />
      <Button
        variant="contained" onClick={onNext}
        style={{ width: "fit-content", alignSelf: "center" }}
      >
        Start {name} Section
      </Button>
    </Box>
  ) : null;
}

export default SectionInstructions;