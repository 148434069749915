import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { makeStyles } from "@mui/styles"
import TimelineDot from '@mui/lab/TimelineDot';

const testimonials = [
  {
    title: "Vishal Saini",
    subtitle: "TOEFL Score : 107",
    imageUrl: "https://assets.languify.in/images/testimonial-vishal-saini.jpeg",
    href: "https://www.linkedin.com/in/vishal-saini-8927461b9/",
    testimonial: "I really love the feedback of individual questions as they help me in overall."
  },
  {
    title: "Tanay gandhi",
    subtitle: "TOEFL Score: 105",
    imageUrl: "https://assets.languify.in/images/person.svg",
    href: "https://www.linkedin.com/in/tanaygandhi306",
    testimonial: "I would recomend this tests to everybody who are short on time & want to make use of this toefl simulated test's."
  },
  {
    title: "Sayli",
    subtitle: "TOEFL Score: 112",
    imageUrl: "https://assets.languify.in/images/testimonial-sayli-pednekar.jpeg",
    href: "https://www.linkedin.com/in/sayli-pednekar-2402/",
    testimonial: "Languify's Unlimited mock practises helped me in increasing my score by several points."
  },
  {
    title: "Shruti Patnaik",
    subtitle: "TOEFL Score: 100",
    imageUrl: "https://assets.languify.in/images/testimonial-shruti-patnaik.jpeg",
    href: "https://www.linkedin.com/in/shruti-patnaik-9925741a1",
    testimonial: "Languifys mock helped me to practise daily and increase my score."
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100vw", overflow: "hidden", height: "fit-content",
    [theme.breakpoints.down("md")]: {
      height: "fit-content", margin: "24px 0px", width: "100vw",
    }
  },
  container: {
    overflow: "hidden", backgroundColor: "#001929", display: "flex",
    flexDirection: "column", alignItems: "flex-start",
    [theme.breakpoints.down('md')]: { borderRadius: 0, },
  },
  scrollContainer: {
    "&::-webkit-scrollbar": { width: 0, height: 0 },
    "&::-webkit-scrollbar-thumb": { width: 0, height: 0, },
  },
  parentContainer: {
    margin: "2em auto", height: "90vh", width: "calc(100vw - 48px)",
    overflow: "hidden",
    [theme.breakpoints.down(725)]: { height: "fit-content" }
  },
  timelineContainer: {
    display: "flex", justifyContent: "flex-end", width: "100%",
    [theme.breakpoints.down(725)]: { justifyContent: "center" }
  },
  testimonial: {
    minWidth: 600, maxWidth: 600, width: 600, borderRadius: 8, height: 250,
    backgroundColor: "white", minHeight: 250, maxHeight: 250, margin: "0 32px",
    padding: 48,
    [theme.breakpoints.down(725)]: {
      maxWidth: "calc(100vw - 48px)", minWidth: "calc(100vw - 48px)",
      margin: "0 16px", padding: 24, minHeight: 200, height: 200,
    }
  },
  imageContainer: {
    width: 80, minWidth: 80, height: 80, minHeight: 80, marginRight: "15px",
    borderRadius: "50%", border: "1px solid grey", overflow: "hidden",
    display: "flex", justifyContent: "center", alignItems: "center",
    [theme.breakpoints.down(725)]: {
      minWidth: 40, minHeight: 40, height: 40, width: 40, margin: "0 1em 0 0"
    }
  },
  authorContainer: {
    height: "40%", display: "flex", alignItems: "flex-start",
    position: "relative",
    [theme.breakpoints.down(725)]: { height: "unset" }
  },
  socialIcon: {
    position: "absolute", top: 0, right: -8, fontSize: 48,
    [theme.breakpoints.down(725)]: {
      fontSize: 30, top: -16, right: -16,
    }
  }
}));

function Testimonial({ index, title, subtitle, imageUrl, testimonial, href }) {
  const classes = useStyles();

  return (
    <Box
      id={`testimonial-${index}`} className={classes.testimonial} mx={5} p={6}
    >
      <Box className={classes.authorContainer}>
        <Box className={classes.imageContainer}>
          <img
            src={imageUrl} alt="title" width="100%" height="100%;"
            style={{ objectFit: "contain" }}
          />
        </Box>
        <Box alignSelf="flex-start" flexGrow={1}>
          <Typography
            fontSize={"2vh"} letterSpacing={0} fontFamily='Inter'
            fontStyle="normal"
          >
            {title}
          </Typography>
          <Typography
            color="primary" fontWeight="light" fontSize={"1.8vh"}
            fontFamily='Inter' fontStyle="normal"
          >
            {subtitle}
          </Typography>
        </Box>
        {(href.length) ? (<Box
          className={classes.socialIcon}
          component="a" href={href} target="_blank" rel="noreferrer"
        >
          <LinkedInIcon color="primary" fontSize="inherit" />
        </Box>) : ""}
      </Box>
      <Box height="40%" maxHeight={"60%"} mt={4}>
        <Typography fontWeight="light" variant="body1" fontSize="1.6vh">
          "{testimonial?.substring(0, 350)}{testimonial?.length > 350 && "..."}"
        </Typography>
      </Box>
    </Box>
  );
}

function Testimonials() {
  const classes = useStyles();

  const ref = React.useRef();

  const [active, setActive] = React.useState(0);

  React.useEffect(() => {
    viewTestimonial(active);
  }, [active]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => setActive((prev) => {
      if (prev < testimonials.length - 1) return prev + 1;
      else return 0;
    }), 3000);

    return () => clearTimeout(timeoutId)
  }, [active]);

  const viewTestimonial = (id) => {
    const { width } = ref.current.getBoundingClientRect();

    const cardWidth = width / testimonials.length;

    const left = (id * (cardWidth));

    document.getElementById("testimonials-container").style.transform = `translateX(-${left}px)`;

    setActive(id);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container} id="testimonials">
        <Box
          pl={4} pt={4} width="100%" display="flex" justifyContent="flex-start"
          alignItems="center"
        >
          <Typography
            color="white.main" fontWeight={"bold"} fontSize={"3.0vmax"}
            lineHeight="3.5vmax" variant="h1"
          >
            Student Testimonials
          </Typography>
        </Box>
        <Box
          position="relative" height={"35vh"} width="calc(100vw - 48px)"
        >
          <Box
            id="testimonials-container" ref={ref} mt={4} width="fit-content"
            display="flex" alignItems="center" overflow="auto hidden"
            className={classes.scrollContainer}
            style={{ transition: "all 0.5s ease-in-out" }}
          >
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index} {...testimonial} index={index} />
            ))}
          </Box>
        </Box>
        <Box className={classes.timelineContainer} my={3} pr={3}>
          {testimonials.map((testimonial, index) => (
            <TimelineDot
              key={index}
              onClick={() => viewTestimonial(index)}
              style={{ marginInlineStart: 8, cursor: "pointer" }}
              color={index === active ? "primary" : "grey"}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Testimonials;