import { useState } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StarIcon from '@mui/icons-material/Star';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { useSnackbar } from "contexts";
import { postFeedback } from "services";
import CenterFlexBox from "./CenterFlexBox";

const sx = {
  root: {
    position: 'absolute', width: "100%", overflow: "hidden",
    bottom: 0, transition: "height 0.5s linear",
  },
  container: {
    mx: "auto", background: "#F0F8FF", width: 400, display: "flex", p: 1,
    justifyContent: "center", alignItems: "center", flexDirection: "column",
    position: 'relative'
  },
  rating: { my: 1 },
  message: {
    fontFamily: "Inter", fontStyle: "normal", fontWeight: 500,
    fontSize: 16, textAlign: "center",
  },
  icon: {
    position: 'absolute', top: '5px', right: '5px',
    color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer'
  }
};

function RateExperience({ active }) {
  const snackbar = useSnackbar();
  const [ratingValue, setRatingValue] = useState(3);
  const [comment, setComment] = useState("");
  const [submitted, setSubmited] = useState(false);
  const [rated, setRated] = useState(false);

  const handleSubmit = async (e) => {
    try {
      if (e) setRatingValue(e.target.value);

      await postFeedback(ratingValue, comment, "MOCK_TEST");

      if (!e) {
        setSubmited(true);

        snackbar.showSnackbar(
          "Thanks for taking the time to share your thoughts with us!",
          "success"
        );
      } else {
        setRated(true);
      }

    } catch (error) {
      console.error(error);

      snackbar.showSnackbar(
        "Uh Oh! Unable to submit your feedback. Please try again!", "error",
        true
      );
    }
  };

  return (
    <Box
      sx={sx.root}
      style={{ height: (active && !submitted) ? 120 : 0 }}
    >
      <Box sx={sx.container}>
        <Typography
          variant="body1"
          sx={sx.message}
        >
          We’d love to hear from you!
        </Typography>
        <CancelOutlinedIcon sx={sx.icon} onClick={() => setSubmited(true)} />
        {
          (rated) ?
            <Typography
              my={1} fontFamily='inter' fontSize={14}
              fontWeight={500} color='#41D7A1'
            >
              Your rating is valuable!
            </Typography> :
            <Rating
              value={Number(ratingValue)}
              precision={1}
              onChange={handleSubmit}
              size="large"
              sx={sx.rating}
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55, color: "#D9D9D9" }}
                  fontSize="inherit" />
              }
            />
        }
        <CenterFlexBox gap={2}>
          <textarea
            cols={30}
            value={comment}
            onChange={(e) => { setComment(e.target.value) }}
            placeholder={
              (rated ?
                'Help us make it even better by sharing your feedback' :
                'Tell us more...'
              )}
            spellCheck="false"
            style={{
              borderRadius: 4, borderColor: '#093654', background: '#F0F8FF'
            }}
          ></textarea>

          <img
            src="https://assets.languify.in/images/send-icon.svg"
            alt="send"
            width={30}
            onClick={() => { handleSubmit() }}
            style={{
              cursor: 'pointer',
            }}
          />

        </CenterFlexBox>
      </Box>
    </Box>
  );
}

export default RateExperience;