import shallow from 'zustand/shallow';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import DialogTransition from 'components/DialogTransition';
import {
  instructions, ListItem
} from 'components/IELTSMock/SectionInstructions';

import useStore from 'utils/ieltsStore';

export default function InstructionsDialog({ open, onClose }) {
  const [section, sections] = useStore(state => [
    state.section, state.sections
  ], shallow);

  const { type = "READING" } = sections?.[section] || {};

  return (
    <Dialog
      keepMounted open={open} TransitionComponent={DialogTransition}
      onClose={onClose} maxWidth="lg"
    >
      <DialogTitle style={{ textTransform: "capitalize" }}>
        {type} Section Instructions
      </DialogTitle>
      <Divider light />
      <DialogContent sx={{ px: 6, display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" fontWeight="500">
          Instructions to Test Takers
        </Typography>
        {instructions[type.toUpperCase()]?.to?.map((instruction, i) => (
          <ListItem key={i}>{instruction}</ListItem>
        ))}
        <br />
        <Typography variant="subtitle1" fontWeight="500">
          Instructions to Test Takers
        </Typography>
        {instructions[type.toUpperCase()]?.for?.map((instruction, i) => (
          <ListItem key={i}>{instruction}</ListItem>
        ))}
      </DialogContent>
      <DialogActions sx={{ px: 6 }}>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}