import React from 'react';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles({
    table: {
        bordeCollapse: 'collapse',
        borderColor: 'transparent',
        borderRadius: '1em',
        borderSpacing: 0,
        boxShadow: '0 0 8px 4px #f1ebeb',
        tableLayout: 'fixed',
        width: '100%',
    },
    td: {
        width: '30%',
        border: 'none',
        color: 'black',
        fontFamily: 'inherit',
        fontSize: '12px',
        fontWeight: 500,
        padding: '12px 16px',
        textAlign: 'center',
        verticalAlign: 'top',
        borderRight: '1px solid black',
        '&:last-child': {
            borderRight: 'none',
        }
    },
    th: {
        width: '30%',
        maxWidth: '30%',
        minWidth: '30%',
        backgroundColor: '#02569d',
        border: 'none',
        color: '#fff',
        fontFamily: 'inherit',
        fontSize: '15px',
        fontWeight: 600,
        padding: '10px 5px',
        overflow: 'hidden',
        wordBreak: 'normal',
        verticalAlign: 'middle',
        '&:first-child': {
            borderRadius: '1em 0 0 0'
        },
        '&:last-child': {
            borderRadius: '0 1em 0 0',
        },
    },
    tableFooter: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        border: "1px solid white",
        borderRadius: '0px 0px 4px 4px',
        backgroundColor: '#0F3854',
    }
});

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5, }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                color="white"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                color="white"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                color="white"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                color="white"
            >
                <LastPageIcon />
            </IconButton>
        </Box>
    );
}

export function Table({
    rows = [],
    columns = [],
    tableClass,
    thClass,
    tdClass,
    pagination,
    htmlContent = false,
    ...props
}) {
    const classes = useStyle();

    const [page, setPage] = React.useState(0);

    const handleChangePage = (e, page) => setPage(page);

    return (
        <Box {...props}>
            <table className={tableClass || classes.table}>
                <tbody>
                    <tr>
                        {columns.map((header, index) =>
                            <th className={thClass || classes.th} key={index}>
                                {header}
                            </th>
                        )}
                    </tr>
                    {rows.slice(page * 5, (page + 1) * 5).map((row, index) => (
                        <tr key={index}>
                            {
                                Object.values(row).map((value, index) => (
                                    (htmlContent) ?
                                        <td key={index}
                                            className={tdClass || classes.td}
                                            dangerouslySetInnerHTML={{ __html: value }}
                                        >
                                        </td> :
                                        <td key={index}
                                            className={tdClass || classes.td}
                                        >
                                            {value}
                                        </td>
                                ))
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
            {pagination && (
                <Box className={classes.tableFooter}>
                    <Typography color="white.main" component="span">
                        {(page * 5)}-{((page + 1) * 5)} of {15}
                    </Typography>
                    <TablePaginationActions
                        count={rows.length}
                        page={page}
                        rowsPerPage={5}
                        onPageChange={handleChangePage}
                    />
                </Box>
            )}
        </Box>
    );
}