import React from 'react';
import Button from 'components/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogTransition from 'components/DialogTransition';
import Divider from '@mui/material/Divider';

import { triggerEventForGA } from 'utils';

export default function PauseTestDialog({ open, onConfirm, onCancel }) {

  const handleClick = () => {
    triggerEventForGA('save_progress_get_report_clicked');
    onConfirm();
  }

  return (
    <Dialog
      keepMounted open={open} TransitionComponent={DialogTransition}
      onClose={onCancel} maxWidth="lg"
    >
      <DialogTitle style={{ textTransform: "capitalize" }}>
        Resume test later and get feedback report for your current progress
      </DialogTitle>
      <Divider light />
      <DialogContent sx={{ px: 6 }}>
      </DialogContent>
      <DialogActions sx={{ px: 6 }}>
        <Button
          variant="contained" onClick={handleClick} sx={{ flexGrow: 1 }}
          color="error"
        >
          Save Progress & Get Report
        </Button>
        <Button variant="contained" onClick={onCancel} sx={{ flexGrow: 1 }}>
          Resume
        </Button>
      </DialogActions>
    </Dialog>
  );
}