import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import useStore from 'utils/ieltsStore';

const useStyles = makeStyles((theme) => ({
  fib: { marginRight: "1ch", marginLeft: "2px" },
  input: {
    marginLeft: "4px",
    minWidth: 60,
    width: 60,
    fontSize: 12,
    fontWeight: "normal",
    background: "#F1F2F6",
    borderRadius: "6px",
    padding: 4,
    border: '1px solid #49B6FF88',
    outlineColor: "#49B6FF"
  }
}));

export function FIBInput({ index, value, defaultValue, handleChange, handleInput }) {
  const classes = useStyles();
  const inputRef = React.useRef();
  const currentQuestion = useStore(state => state.currentQuestion);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);

  React.useEffect(() => {
    if (currentQuestion === index) {
      inputRef.current?.scrollIntoView({ block: "nearest" });
      inputRef.current?.focus();
    }
  }, [currentQuestion, index]);

  React.useEffect(() => {
    inputRef.current.value = defaultValue || '';
  }, [defaultValue]);

  return (
    <span className={classes.fib}>
      <b>{index + 1}</b>
      <input
        ref={inputRef}
        value={value}
        onChange={handleChange}
        onInput={handleInput}
        className={classes.input}
        onFocus={() => setCurrentQuestion(index)}
      />
    </span>
  );
}

function FIB({ index, question, ...props }) {
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    setValue(question?.answer?.value || '');
  }, [question]);

  const handleChange = (e) => {
    const value = e.target.value;

    setValue(value);

    if (question) question.answer.value = value;
  };

  return (
    <Box mb={2} fontSize={14} fontWeight={400} fontFamily="Inter" {...props}>
      {question?.content?.map((part, i) => part === '__FIB__' ? (
        <FIBInput key={index} index={index} handleChange={handleChange} value={value} />
      ) : (
        part
      ))}
    </Box>
  );
}

export default FIB;