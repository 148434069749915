import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "components/Button";
import CenterFlexBox from "components/CenterFlexBox";
import { useEffect } from "react";

export default function EndAssessment() {

    const raiseEvent = () => {
        window?.parent?.postMessage("ASSESSMENT_SUBMITTED", "*");
        console.log('Message is sent to the parent window.');
    }

    useEffect(() => {
        raiseEvent();
    }, []);

    return (
        <CenterFlexBox height={'100vh'} backgroundColor='#001929'>
            <Box
                display="flex" height="fit-content" alignItems='center'
                justifyContent="center" position="relative" mx="auto" width={450}
                flexDirection="column" backgroundColor="#FFFFFF" borderRadius={2}
                px={6} py={2} boxShadow='0px 4px 24px rgba(0, 0, 0, 0.16)'
            >

                <Typography
                    fontFamily='Inter' fontSize={18} fontWeight={500} textAlign='center'
                >
                    You have successfully completed the test
                </Typography>

                <Typography
                    fontFamily='Inter' fontSize={14} fontWeight={400} textAlign='center'
                    mb={1}
                >
                    Please wait...
                </Typography>

                <Button variant='contained' style={{ width: 150 }} onClick={raiseEvent} >
                    Next
                </Button>

            </Box>
        </CenterFlexBox>
    );
}