import { useEffect, useState } from 'react';

const getReturnValues = (countDown) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds, countDown];
};

export const useCountDown = (seconds) => {

  const [countDown, setCountDown] = useState(new Date().getTime() + (seconds * 1000));

  useEffect(() => {
    const countDownDate = new Date().getTime() + (seconds * 1000);

    setCountDown(countDownDate);

    const interval = setInterval(() => {
      const time = countDownDate - new Date().getTime()

      setCountDown(time);

      if (time <= 0) clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  return getReturnValues(countDown);
};

export default useCountDown;