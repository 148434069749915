import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import RefreshIcon from "@mui/icons-material/Cached";

const ConnectivityProviderContext = React.createContext({
  connected: false,
  checkConnected: () => { }
});

function TransitionDown(props) {
  return <Slide {...props} direction="right" />;
}

const ConnectivityProvider = ({ children }) => {
  const [_connected, _setConnected] = React.useState(navigator.onLine);

  const checkConnected = React.useCallback((e) => {
    fetch("https://api.talkeng.in", { method: "get", mode: "no-cors" })
      .then(() => _setConnected(true))
      .catch((e) => {
        console.error(JSON.stringify(e));
        _setConnected(false);
      });
  }, []);

  React.useEffect(() => {
    if (navigator.connection)
      navigator.connection.addEventListener("change", checkConnected);

    return () =>
      navigator?.connection?.removeEventListener("change", checkConnected);
  }, []);

  React.useEffect(() => {
    window.addEventListener("online", checkConnected);

    return () => window.removeEventListener("online", checkConnected);
  }, []);

  React.useEffect(() => {
    window.addEventListener("offline", checkConnected);

    return () => window.removeEventListener("offline", checkConnected);
  }, []);

  return (
    <ConnectivityProviderContext.Provider
      value={{
        connected: _connected,
        checkConnected: checkConnected
      }}
    >
      <Snackbar
        open={!_connected}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        TransitionComponent={TransitionDown}
        transitionDuration={{ enter: 200, exit: 1000 }}
      >
        <Alert
          severity={_connected ? "success" : "error"}
          sx={{ width: "100%" }}
          action={_connected ? null : (
            <Button
              color="primary"
              size="small"
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={checkConnected}
            >
              Check Now
            </Button>
          )}
        >
          <AlertTitle>No Connection</AlertTitle>
          You are not connected to Internet!
        </Alert>
      </Snackbar>
      {children}
    </ConnectivityProviderContext.Provider>
  );
};

const useConnected = () => {
  const { connected } = React.useContext(ConnectivityProviderContext);

  return connected;
};

export { ConnectivityProviderContext, ConnectivityProvider, useConnected };