import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import MUIButton from "@mui/material/Button";
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from 'components/Sidebar';
import ProfileMenu from "./ProfileMenu";
import Session from "utils/Session";
import ProductMenu from "./ProductMenu";

const useStyles = makeStyles(theme => ({
    header: {
        height: "80px",
        left: "0px",
        top: "0px",
        padding: "20px 0px",
        backgroundColor: "#001929",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        [theme.breakpoints.down("md")]: {
            justifyContent: "space-between",
        }
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: 1400,
        margin: "0 auto",
        padding: "0 54px",
        [theme.breakpoints.down("md")]: {
            width: "85%"
        },
        [theme.breakpoints.down("md")]: {
            width: "95%",
            padding: 0
        }
    },
    desktopElement: {
        paddingRight: 50,
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    mobileElement: {
        display: "none",
        cursor: "pointer",
        height: "-webkit-fill-available",
        margin: "16px 32px",
        [theme.breakpoints.down("md")]: {
            display: "block",
        }
    },
    downloadButtonStyle: {
        borderRadius: "8px",
        padding: "15px 30px"
    },
    signinButtonStyle: {
        color: "white",
        textTransform: "none",
        marginRight: "40px",
        height: "10%"
    },
    logoContainer: {
        cursor: "pointer",
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            marginLeft: theme.spacing(0),
        },
    },
    logo: {
        [theme.breakpoints.down("md")]: {
            height: theme.spacing(4),
        },
    }
}));

export const MenuItems = [
    { title: "About Us", link: "https://languify.in/aboutus" },
    {
        title: "Blog",
        link: 'https://peerboard.com/1935902282',
        newTab: true,
    },
    {
        title: "Fellowship",
        link: process.env.REACT_APP_CAP_URL,
        newTab: true
    },
    {
        title: "Get In Touch",
        link: process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL,
        newTab: true,
    },
];

const MenuLink = ({ title, link, newTab, active }) => {
    return (
        <MUIButton
            sx={{
                textTransform: "none",
                color: active ? 'primary' : 'white.main',
            }}
            LinkComponent="a"
            href={link}
            target={newTab ? "_blank" : "_self"}
        >
            {title}
        </MUIButton>
    );
}

function Header({ menuItems }) {
    const classes = useStyles();
    const location = useLocation();

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleOpenSidebar = () => setSidebarOpen(true)

    const _menuItems = menuItems || MenuItems;

    return (
        <Box className={classes.header} >
            <Box className={classes.logoContainer}>
                <Link to="/" style={{ textDecoration: "none" }}>
                    <img
                        src={"https://assets.languify.in/images/lang-logo.svg"}
                        alt="languify" width={150} className={classes.logo}
                    />
                </Link>
            </Box>
            <Box
                flexGrow={1} display="flex" alignItems="center" gap={6}
                justifyContent="flex-end" className={classes.desktopElement}
            >
                <ProductMenu />
                {_menuItems.map((menu, index) => (
                    <MenuLink
                        key={index} title={menu.title} link={menu.link}
                        newTab={menu.newTab} active={location.pathname === menu.link}
                    />
                ))}
                {Session.isLoggedIn() ? (<ProfileMenu />) : (<>
                    <MenuLink title='Login' link='/login' />
                    <Link to="/register" style={{ color: "white", textDecoration: "none" }}>
                        <MUIButton
                            variant="contained"
                            color="subprimary"
                            style={{
                                textTransform: 'none',
                                padding: "4px 16px",
                                margin: 0
                            }}
                        >
                            Sign Up
                        </MUIButton>
                    </Link>
                </>)}
            </Box>
            {!sidebarOpen && (
                <MenuIcon
                    className={classes.mobileElement}
                    sx={{ fontSize: '2rem', color: '#374957' }}
                    onClick={handleOpenSidebar}
                />
            )}
            {sidebarOpen && <Sidebar setSidebarOpen={setSidebarOpen} />}

        </Box>
    );
}

export default Header;